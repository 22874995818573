import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "icon-wrap" }

import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import logo from '@/assets/images/logo.png';
import { useLoginUserDetailStore } from '@/stores/useLoginUserDetailStore';
import api from '@/api/request';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

// @ts-ignore
const router = useRouter();

const userDetailStore = useLoginUserDetailStore();
userDetailStore.tryLoadUserDetail();

const username = ref('');

watch(
  () => userDetailStore.user?.userName,
  () => {
    username.value = userDetailStore.user?.userName ?? '';
  },
  { immediate: true }
);
const logOut = () => {
  api.get('/sso/sign/logout', {}, {}, 'logoutBase');
  router.push({
    path: '/login'
  });
};

return (_ctx: any,_cache: any) => {
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_e_typography = _resolveComponent("e-typography")!
  const _component_e_icon = _resolveComponent("e-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_avatar, {
      shape: "square",
      size: 30,
      src: _unref(logo),
      class: "top-avatar"
    }, null, 8, ["src"]),
    _createVNode(_component_e_typography, {
      heading: "1",
      class: "topbar-title"
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createTextVNode("零洞运营工作台")
      ])),
      _: 1
    }),
    _createVNode(_component_el_popover, {
      placement: "bottom",
      width: 128
    }, {
      reference: _withCtx(() => [
        _createElementVNode("div", {
          class: "logout-wrap",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showPopoverWrap && _ctx.showPopoverWrap(...args)))
        }, [
          _createVNode(_component_e_typography, {
            heading: "2",
            class: "white-color username"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(username.value), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_e_icon, {
              type: "down",
              fill: "#333",
              size: "16"
            })
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_e_typography, {
          heading: "4",
          class: "pointer",
          onClick: logOut
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("退出")
          ])),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})